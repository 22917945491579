import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "gatsby";

import arrow from "../../images/sub-item-arrow.svg";

const BackButton = styled(Link)`
  background-image: url("${arrow}");
  background-repeat: no-repeat;
  display: flex;
  color: white;
  background-size: 40px;
  transform: rotate(180deg);
  justify-content: flex-end;
  background-position: right center;
  padding: 20px 0;

  p {
    transform: rotate(180deg);
    padding-left: 55px;
  }
`;

export default BackButton;
