import React from "react";
import { ModalRoutingContext } from "gatsby-plugin-modal-routing";

const useModal = () => {
  const modalContext = React.useContext(ModalRoutingContext);
  if (modalContext === undefined) {
    throw new Error(
      `useModal must be used within a ModalRoutingContext.Provider`
    );
  }
  return modalContext;
};

export default useModal;
