import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Helmet from "react-helmet";
import { StaticQuery, graphql, Link } from "gatsby";
import { Grid, Row, Col } from "react-styled-flexboxgrid";

import useModal from "../context/ModalContext";

import Layout from "../components/new/Layout";
import Spacer from "../components/new/Spacer";

import AgencyDetail from "../components/new/AgencyDetail";
import BackButton from "../components/new/BackButton";

const Background = styled.section`
  background-color: ${props => props.theme.colors.primary};
  margin: 0 -1rem;

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    margin: 0;
  }
`;

const Agency = ({ pageContext }) => {
  const agency = pageContext.pageData;
  const meta = pageContext.meta;

  if (!agency) {
    return null;
  }

  const { modal } = useModal();

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title={`${agency.agency_name} | Our brands`}
        titleTemplate={`%s | IPG - DXTRA`}
        meta={[
          {
            name: `description`,
            content: agency.meta_description || meta.description,
          },
          {
            property: `og:title`,
            content: agency.meta_title,
          },
          {
            property: `og:description`,
            content: agency.meta_description || meta.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: meta.author,
          },
          {
            name: `twitter:title`,
            content: agency.meta_title || meta.title,
          },
          {
            name: `twitter:description`,
            content: agency.meta_description || meta.description,
          },
        ].concat(meta)}
      />
      <Layout isModal={modal}>
        {modal ? (
          <AgencyDetail
            isModal={modal}
            name={agency.agency_name && agency.agency_name}
            logo={agency.agency_logo}
            overview={agency.agency_overview}
            competencies={agency.agency_competencies}
            homepage={agency.agency_website}
            careersPage={agency.agency_careers_page}
            images={
              agency.body
                ? agency.body[0].__typename ===
                  "PRISMIC_AgencyBodyCapability_agency_media"
                  ? agency.body[1].fields
                  : agency.body[0].fields
                : []
            }
          />
        ) : (
          <Background>
            <Grid>
              <Row>
                <Col xs={12}>
                  <Spacer padding={{ xs: "80px 0 50px", m: "150px 0 100px" }}>
                    <BackButton to="/our-brands">
                      <p>Back to all brands</p>
                    </BackButton>
                    <AgencyDetail
                      isModal={modal}
                      name={agency.agency_name && agency.agency_name}
                      logo={agency.agency_logo}
                      overview={agency.agency_overview}
                      competencies={agency.agency_competencies}
                      homepage={agency.agency_website}
                      careersPage={agency.agency_careers_page}
                      images={
                        agency.body
                          ? agency.body[0].__typename ===
                            "PRISMIC_AgencyBodyCapability_agency_media"
                            ? agency.body[1].fields
                            : agency.body[0].fields
                          : []
                      }
                    />
                  </Spacer>
                </Col>
              </Row>
            </Grid>
          </Background>
        )}
      </Layout>
    </>
  );
};

Agency.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default Agency;
